@import "src/css/styles/variables.scss";

.heading {
    font-size: 1.5rem;
    text-transform: uppercase;
    margin: 0 0 1.875rem 0;
    font-weight: 700;
    letter-spacing: 0.125rem;
    color: white;

    @media (min-width: $grid-breakpoints-md) {
        font-size: 3rem;
        margin: 0 0 3rem 0;
    }

    &::after {
        content: "";
        height: 0.125rem;
        width: 3.125rem;
        background-color: currentColor;
        display: block;
        margin-top: 1rem;
    }
}

.subheading, .subheadingWhite {
    font-size: 1vw;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1rem;
    letter-spacing: 0.125rem;
}

.subheadingWhite{
    color: white;
}