@import "src/css/styles/variables.scss";
@import "src/css/styles/fonts.scss";

html {
    box-sizing: border-box;
    font-size: 16px;
    scroll-behavior: smooth;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

.app {
    line-height: 1.3;
}

.section {
    padding: $section-padding-mobile;
    display: flex;
    line-height: 1.3;
    scroll-margin-top: $nav-height-mobile;

    @media (min-width: $grid-breakpoints-xxl) {
        padding: $section-padding-desktop 0;
        scroll-margin-top: $nav-height-desktop;
    }
}

.container {
    width: 75rem;
    margin: 0 auto;
}

.background-dark {
    background-color: $color-dark-grey;
    color: white;
}
