@import "src/css/styles/variables";

.nav {
    display: flex;
    align-items: center;
    padding: 0 $section-padding-mobile;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    background-color: white;
    z-index: 1;
    @media screen and (max-width: 390px) {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    @media (min-width: $grid-breakpoints-md) {
        height: 6.25rem;
        padding: 0;
    }

    .logo {
        width: 4.375rem;
        height: 4.375rem;

        @media (min-width: $grid-breakpoints-md) {
            width: 6.25rem;
            height: 6.25rem;
        }
    }

    .container {
        display: flex;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 16px;
        @media screen and (min-width: 390px) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100vw;
            overflow-x: auto;
        }
    }

    &__menu-button {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 3rem;
        height: 3rem;
        background-color: transparent;
        border: 0;
        margin-left: auto;
        padding: 0.5625rem 0;

        @media (min-width: 769px) {
            display: none;
        }

        &.is--active > span {
            background-color: rgba( $color-dark-grey, 0.75);
        }

        > span {
            background-color: $color-dark-grey;
            height: 0.25rem;
            display: block;
            width: 100%;
            border-radius: 0.125rem;
        }
    }

    &__list {
        display: flex;
        list-style: none;
        margin: 0 0 0 3.75rem;
        padding: 0;

        @media (max-width: $grid-breakpoints-md) {
            display: none;
            background-color: $color-dark-grey;
            color: white;
            margin: 0;
        }

        &.is--open {
            @media (max-width: $grid-breakpoints-md) {
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 48px;
                right: 0;
                left: 0;
            }
            .tllogonav{
                @media screen and (max-width: $grid-breakpoints-md) {
                    display: flex;
                    justify-content: center;
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
        }

        > li {
            display: flex;
        }

        &-link {
            text-transform: uppercase;
            color: $color-dark-grey;
            text-decoration: none;
            font-size: 1rem;
            padding: 1rem 1.875rem;
            position: relative;
            font-weight: 500;
            display: flex;
            align-items: baseline;

            @media (max-width: $grid-breakpoints-md) {
                color: white;
                padding: 1rem;
                width: 100%;
            }

            @media screen and (min-width: 1920px){
                font-size: 0.9vw;
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 1.875rem;
                right: 1.875rem;
                height: 0.125rem;
                background-color: $color-dark-grey;
                transition: opacity 0.3s, transform 0.3s;
                transform: scale(0);
                transform-origin: center;
            }

            &:hover::after,
            &:focus::after {
                opacity: 1;
                transform: scale(1);
            }

            .icon-external {
                margin-left: 0.5rem;
                height: 0.875rem;
                width: auto;
            }
        }
    }
}

.awtflogonav{
    display: flex;
    align-items: center;
    margin-right: 25px;
    @media screen and (max-width: $grid-breakpoints-md) {
        display: none;
    }
}

.tllogonav{
    display: flex;
    align-items: center;
    margin-right: 50px;
    margin-left: 25px;
}

.tlxaw{
    display: flex;
    flex-direction: row;
}

.theX{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    font-weight: 600;
    color: black;
    margin: 0 10px;
}

.mobileLogo{
    @media (min-width: 769px) {
        display: none;
    }
}