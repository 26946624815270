@import "src/css/styles/variables";

@font-face {
    font-family: myKaneda;
    src: url(./../../assets/fonts/KanedaGothic-Bold.ttf);
}

@font-face {
    font-family: myKanedaLight;
    src: url(./../../assets/fonts/KanedaGothic-Light.ttf);
}

@font-face {
    font-family: helveticanowtext;
    src: url(./../../assets/fonts/HelveticaNowText/Fonts/hnt.ttf);
}

@font-face {
    font-family: kgbold;
    src: url(./../../assets/fonts/KanedaGothic/kgbold.otf);
}

@font-face {
    font-family: kgregular;
    src: url(./../../assets/fonts/KanedaGothic/kgregular.otf);
}

.openingImage{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 75vh;
    width: 100%;
    position: relative;
}

.videoCarousel{
    text-transform: uppercase;
    font-weight: bold;
}

.videoCarouselH1{
    font-size: 80px;
    letter-spacing: 2px;
    font-family: kgregular;
    line-height: 1.325;
    font-weight: 700;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 50%);
    color: white;
    @media screen and (max-width: 576px){
        font-size: 36px;
    }
}

.videoCarouselP {
    font-size: 16px;
    letter-spacing: 5px;
    margin: 0;
    text-shadow: 0px 4px 4px rgb(0 0 0 / 50%);
    color: white;
    @media screen and (max-width: 576px){
        font-size: 15px;
    }
}

.readMoreButton{
    margin-top: 25px;
    letter-spacing: 2px;
    font-family: helveticanowtext;
    @media screen and (max-width: 390px){
        > span{
            font-size: 8px;
        }
    }

    @media screen and (min-width: 2500px){
        > span{
            margin-top: 0.2vw;
            font-size: 1.1vw;
        }
    }
}

.readMoreButton:hover{
    cursor: pointer;
}

@media screen and (min-width: 2500px){
    a.ant-btn{
        height: 2vw;
    }
}