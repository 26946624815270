$color-dark-grey: #292829;
$section-padding-mobile: 2rem;
$section-padding-desktop: 3.75rem;
$nav-height-desktop: 6.25rem;
$nav-height-mobile: 4.375rem;

$grid-breakpoints-xs: 0;
$grid-breakpoints-sm: 576px;
$grid-breakpoints-md: 768px;
$grid-breakpoints-lg: 992px;
$grid-breakpoints-xl: 1200px;
$grid-breakpoints-xxl: 1400px;
