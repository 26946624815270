@import "src/css/styles/variables";

@font-face {
    font-family: myKaneda;
    src: url(./../../assets/fonts/KanedaGothic-Bold.ttf);
}

@font-face {
    font-family: myKanedaLight;
    src: url(./../../assets/fonts/KanedaGothic-Light.ttf);
}

@font-face {
    font-family: helveticanowtext;
    src: url(./../../assets/fonts/HelveticaNowText/Fonts/hnt.ttf);
}

@font-face {
    font-family: kgbold;
    src: url(./../../assets/fonts/KanedaGothic/kgbold.otf);
}

@font-face {
    font-family: kgregular;
    src: url(./../../assets/fonts/KanedaGothic/kgregular.otf);
}


.facilityInfo{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(transparent 0%, transparent 30%, #031422 30%, #031422 100%);
    padding: 0px 10% 64px 10%;
    @media screen and (max-width: 576px){
        background: linear-gradient(transparent 0%, transparent 25%, #031422 25%, #031422 100%);
    }
}

.stateOfTheArt{
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    color: white;
    font-family: myKaneda;
}

.facilityTour{
    display:flex;
    justify-content: center;
    margin-bottom: 64px;
}

.facilityTourVideo{
    margin-top: 16px;
}

.facilityStat{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: white;

    > div{
        margin-right: 24px;
    }

    @media screen and (max-width: 1023px) {
        flex-direction: column;
    }
}

.facilityStatBig, .facilityStatBigTwo, .facilityStatBigThree, .facilityStatBigFour{
    font-size: 36px;
    font-weight: 700;
    font-family: kgregular;
    font-style: normal;
    line-height: 43px;
    text-transform: uppercase;
    width: 282px;
    height: auto;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.facilityStatDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 576px) {
        margin-bottom: 12px;
    }
}

.myAnimation{
    animation: slide-down 2s;
}

@keyframes slide-down {
    0% {
        transform: translateY(-500%);
    }
    100% {
        transform: translateY(0%);
    }
}

.facilityStatSmall{
    font-family: Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

@media screen and (min-width: 391px) and (max-width: 820px) {
    .facilityStatSmall{
        font-size: 2vw;
    }
    .facilityStatBig, .facilityStatBigTwo, .facilityStatBigThree, .facilityStatBigFour{
        height: auto;
    }
}

@media screen and (max-width: 576px) {
    .facilityTourVideo{
        width: 300px;
        height: 150px;
    }
    .facilityTour{
        margin-bottom: 24px;
    }
}

@media screen and (min-width: 1920px){
    .facilityTourVideo{
        width: 50vw;
        height: 50vh;
    }
}

@media screen and (min-width: 756px) and (max-width: 1440px)  {
    .facilityStat{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
    }
}