@import "src/css/styles/variables";

.galleryImages{
    display: flex;
    flex-direction: column;
    margin-bottom: 10vh;
}

.galleryTopImage{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: calc(60vw + 4vh);
    height: 320px;
    background-image: (url(../../../images/Photoshoots/1.jpg));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 2vh;
}

.galleryMiddleSectorLeft{
    display: flex;
    width: 20vw;
    height: 90vh;
    margin-right: 2vh;
    background-image: (url(../../../images/Photoshoots/2.jpg));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.galleryMiddleSector{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: auto;
}

.galleryMiddleSectorMiddle{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20vw;
    height: 90vh;
    margin-right: 2vh;
}

.galleryMiddleSectorMiddleTop{
    width: 20vw;
    height: 29vh;
    background-image: (url(../../../images/Photoshoots/10.jpg));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.galleryMiddleSectorMiddleBottom{
    width: 20vw;
    height: 59vh;
    background-image: (url(../../../images/Photoshoots/6.jpg));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.galleryMiddleSectorRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 20vw;
    height: 90vh;   
}

.galleryMiddleSectorRightTop{
    width: 20vw;
    height: 59vh;
    background-image: (url(../../../images/Photoshoots/7.jpg));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.galleryMiddleSectorRightBottom{
    width: 20vw;
    height: 29vh;
    background-image: (url(../../../images/Photoshoots/4.jpg));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.galleryBottomImage{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: calc(60vw + 4vh);
    height: 320px;
    background-image: (url(../../../images/Photoshoots/awtf.png));
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 2vh;
}

@media screen and (max-width: 768px){
    .galleryTopImage{
        height: 25vh;
    }

    .galleryMiddleSector{
        flex-direction: column;
    }
    .galleryMiddleSectorLeft, .galleryMiddleSectorRight, .galleryMiddleSectorMiddle{
        margin-bottom: 2vw;
        margin-right: 0;
        width: calc(60vw + 4vh);
        height: 60vh;
    }
    .galleryMiddleSectorMiddleTop, .galleryMiddleSectorMiddleBottom, .galleryMiddleSectorRightTop, .galleryMiddleSectorRightBottom{
        width: calc(60vw + 4vh);
        margin-right: 0;
    }
    .galleryMiddleSectorRightTop, .galleryMiddleSectorMiddleTop{
        margin-bottom: 2vw;
    }
    .galleryBottomImage{
        margin-top: 0;
        height: 25vh;
    }
    .galleryMiddleSectorRightBottom{
        height: 59vh;
    }
}