@import "src/css/styles/variables";

@font-face {
    font-family: myKaneda;
    src: url(./../../assets/fonts/KanedaGothic-Bold.ttf);
}

@font-face {
    font-family: myKanedaLight;
    src: url(./../../assets/fonts/KanedaGothic-Light.ttf);
}

@font-face {
    font-family: helveticanowtext;
    src: url(./../../assets/fonts/HelveticaNowText/Fonts/5761054/2a13627f-dba8-48e1-935c-a404f5293139.woff2);
}

.prolabContent{
    padding: 60px 0px 60px 0px;
    background-color: #031422;
    border-top: 1px solid white;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: auto;
    @media screen and (max-width: 700px) {
        flex-direction: column;
    }

    &Part{
        display: flex;
        flex-direction: column;
        flex: 0.5;
        padding-right: 10%;
        margin-left: 48px;
        height: auto;
        overflow: auto;

        @media screen and (max-width: 700px) {
            align-items: center;
            padding: 5% 5% 5% 5%;
            margin-left: 0;
            flex: 1;
        }
        
        > p{
            font-size: 14px;
            margin-bottom: 20px;
            font-family: helveticanowtext;
            font-style: normal;
            font-weight: 400;
            color: white;
        }
    }

    &ImagePart{
        display: flex;
        flex: 0.5;
        > img{
            width: 100%;
            height: auto;
        }
        @media screen and (max-width: 700px) {
            justify-content: center;
            > img{
                width: 400px;
                height: 250px;
            }
        }
        @media screen and (min-width: 700px) and (max-width: $grid-breakpoints-lg) {
            align-items: center;
            > img{
                height: 60%;
            }
        }
    }
}

.learnMoreAbout{
    display:flex;
    flex-direction: row;
    color: #AD9742;
    font-weight: bold;
    @media screen and (min-width: 1920px) {
        font-size: 1vw;
    }
}

.prolabContentPart::-webkit-scrollbar {
    display: none;
}

.headingPart{
    margin-top: 1rem;
    font-weight: bold;
}

.prolabSection{
    scroll-margin-top: 50px;
}

.prolabHeaderH1{
    text-align: center;
    font-family: myKaneda;
    margin-bottom: 2vw;
    line-height: 48px;
    @media screen and (max-width: $grid-breakpoints-md) {
        font-size: 6vw;
    }
    @media screen and (min-width: $grid-breakpoints-md){
        font-size: 4vw;
    }
}

.prolabHeaderH2{
    font-family: myKaneda;
    font-size: 36px;
    margin-bottom: 10px;
}

.theprolabfeatures{
    padding: 5% 10% 5% 10%;
}

.featuredItems{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    vertical-align: top;
    overflow: hidden;
    @media screen and (max-width:1280px) {
        flex-direction: column;
        align-items: center;
    }
}

.featuredItem{
    margin-right: 24px;
    height: auto;
    width: 50vw;
    @media screen and (max-width: 390px){
        margin-right: 0px;
    }
    @media screen and (max-width:1280px) {
        margin-bottom: 48px;
        display: block;
    }
}

.featuredItem > p{
    margin-top: 10px;
    font-size: 1vw;
    @media screen and (min-width: $grid-breakpoints-md) and (max-width: 1280px) {
        font-size: 1.5vw;
    }
    @media screen and (min-width: $grid-breakpoints-sm) and (max-width: $grid-breakpoints-md) {
        font-size: 2.2vw;
    }
    @media screen and (max-width: $grid-breakpoints-sm) {
        font-size: 3vw;
    }
}

.featuredItem > div > p{
    font-size: 1vw;
    @media screen and (min-width: $grid-breakpoints-md) and (max-width: 1280px) {
        font-size: 1.5vw;
    }
    @media screen and (min-width: $grid-breakpoints-sm) and (max-width: $grid-breakpoints-md) {
        font-size: 2.2vw;
    }
    @media screen and (max-width: $grid-breakpoints-sm) {
        font-size: 3vw;
    }
}

.prolabtlImageSection{
    display: flex;
    justify-content: center;
    padding: 5% 15% 5% 15%;
}

.prolabtlImage{
    display: flex;
    justify-content: center;
    transform: scale(2);
}

@media screen and (min-width: 391px) and (max-width:820px) {
    .learnMoreAbout > p{
        margin-bottom: 0px;
    }
}

.knowmorediv{
    display: flex;
    flex-direction: row;
    > p{
        color: #AD9742;
    }
}

.arrowrightdiv{
    margin-top: 5px;
    margin-left: 5px;
    color: #AD9742;
    font-size: 1vw;
    @media screen and (min-width: 1920px) {
        margin-top: 0.3vw;
    }
    @media screen and (min-width: $grid-breakpoints-md) and (max-width: 1280px) {
        font-size: 1.2vw;
    }
    @media screen and (min-width: $grid-breakpoints-sm) and (max-width: $grid-breakpoints-md) {
        font-size: 2.2vw;
    }
    @media screen and (max-width: $grid-breakpoints-sm) {
        font-size: 3vw;
    }
}