@import "src/css/styles/variables";

.footer {
    background-color: #031422;
    color: white;
    display: flex;
    min-height: 26.25rem;

    @media (min-width: $grid-breakpoints-md) {
        min-height: 28.75rem;
    }

    > .container {
        display: flex;
        flex-direction: column;
    }

    &__button {
        font-size: 1.25rem;
        font-weight: 500;
        color: white;
        display: flex;
        align-items: center;
        text-decoration: none;

        @media (hover: hover) and (pointer: fine) {
            &:hover {
                > svg {
                    transform: translateX(0.25rem);
                }
            }
        }


        > svg {
            margin-left: 0.5rem;
            transition: transform 0.2s linear;
        }
    }

    &__contact-options {
        display: flex;
        flex-direction: column;

        @media (min-width: $grid-breakpoints-md) {
            width: 60%;
            flex-direction: row;
        }
    }

    &__pr,
    &__press {
        flex: 1;
        margin-bottom: 2.375rem;
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;

        @media (min-width: $grid-breakpoints-md) {
            flex-direction: row;
            justify-content: space-between;
        }

        > span {
            text-transform: uppercase;
            font-size: 0.625rem;

            @media (max-width: $grid-breakpoints-md) {
                margin-top: 1rem;
            }
        }
    }
}
