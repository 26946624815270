.social-media {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;

    &__link {
        color: white;
        display: flex;
        transition: transform 0.2s linear;

        @media (hover: hover) and (pointer: fine) {
            a:hover {
                transform: scale(1.1);
            }
        }

        &:not(:first-child) {
            margin-left: 0.75rem;
        }
    }
}
