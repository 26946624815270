@import "src/css/styles/variables";

@font-face {
    font-family: myKaneda;
    src: url(./../../assets/fonts/KanedaGothic-Bold.ttf);
}

@font-face {
    font-family: myKanedaLight;
    src: url(./../../assets/fonts/KanedaGothic-Light.ttf);
}

@font-face {
    font-family: helveticanowtext;
    src: url(./../../assets/fonts/HelveticaNowText/Fonts/5761054/2a13627f-dba8-48e1-935c-a404f5293139.woff2);
}

.aboutContent{
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: auto;
    > p{
        @media screen and (min-width: 2500px){
            font-size: 1vw;
        }
        font-size: 14px;
        font-family: helveticanowtext;
        font-weight: 400;
        margin-bottom: 32px;
    }
}

.aboutSection{
    scroll-margin-top: 50px;
    padding: 5% 35% 5% 35%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @media screen and (max-width: 820px){
        padding: 5% 15% 5% 15%;
    }
}

.prolabtlImage{
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
    transform: scale(2);
}

.aboutContent::-webkit-scrollbar {
    display: none;
}